










































@import '../dex-shared/styles/imports.less';

  .container {

    display: flex;
    width: 100%;
    margin-top: 32px;
    padding-top: 64px;

    @media @phone {
      flex-direction: column;
    }

    @media @tablet {
      flex-direction: column;
    }

    @media @desktop {
      flex-direction: row;
      justify-content: center;
    }

  }

  .no-login-message {

    @media @phone {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media @tablet {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media @desktop {
      width: 40%;
    }
  }

  .highlight {
    color: @color-enem-primary;
  }

  .no-login-svg-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .no-login-svg {
    width: 300px;
    height: 100%;

    @media @phone {
      display: none;
    }

    @media @tablet {
      display: none;
    }

    @media @desktop {
      display: flex;
    }
  }

  .streak-fire-container {

    display: flex;
    flex-direction: column;
    width: 100%;

    @media @phone {
      align-items: center;
    }

    @media @tablet {
      align-items: center;
    }

    @media @desktop {
      align-items: flex-start;
    }

  }

  .mobile-tablet-only {
    @media @desktop {
      display: none !important;
    }

    @media @tablet {
      display: flex;
    }

    @media @phone {
      display: flex;
    }
  }

  .desktop-only {
    @media @desktop {
      display: flex !important;
    }

    @media @tablet {
      display: none;
    }

    @media @phone {
      display: none;
    }
  }

  .streak-fire {
     @media @phone {
      width: 35px;
      height: 35px;
      align-self: flex-start;
      margin-bottom: 4px;
    }

    @media @tablet {
      width: 35px;
      height: 35px;
      align-self: flex-start;
      margin-bottom: 4px;
    }

    @media @desktop {
      width: 30px;
      height: 30px;
      margin-right: 16px;
      align-self: flex-start;
    }
  }

  .no-login-cta-button-container {
     @media @phone {
      align-self: center;
    }

    @media @tablet {
      align-self: center;
    }

    @media @desktop {
      align-self: flex-start !important;
    }
  }

  .no-login-cta-button {

     @media @phone {
      align-self: center;
    }

    @media @tablet {
      align-self: center;
    }

    @media @desktop {
      align-self: flex-start !important;
    }

    margin-top: 32px;
  }

  .no-login-message-body-section-container {

    display: flex;
    margin-top: 32px;

    p {
       font-family: "Inter UI";
       font-style: normal;
       font-weight: normal;
       font-size: 20px;
       line-height: 32px;
       letter-spacing: -0.6px;
       text-align: start;
    }

    @media @phone {
      flex-direction: column;
      width: 90%;
    }

    @media @tablet {
      flex-direction: column;
      width: 90%;
    }

    @media @desktop {
      flex-direction: row;
    }
  }

  .no-login-message-header {
    margin-top: 0px;
    line-height: 56px;
    letter-spacing: -1.6px;

    @media @tablet {
      font-size: 32px;
      text-align: start;
      margin-top: 8px;
    }

    @media @phone {
      font-size: 32px;
      text-align: start;
      margin-top: 8px;
    }

    @media @desktop {
      font-size: 32px;
      text-align: start;
    }
  }

  .no-login-message-header-container {

    @media @tablet {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media @phone {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media @desktop {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

  }

  .no-login-message-body {
    font-family: "Inter UI";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.6px;
    color: #6D6D6D;

    @media @phone {
      margin-top: 16px;
      text-align: center;
    }

    @media @tablet {
      margin-top: 16px;
      text-align: center;
    }

    @media @desktop {
      text-align: start !important;
    }
  }
