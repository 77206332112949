
.slide-vertical-enter, .slide-vertical-leave-to {
  margin-bottom: -200px;

  @media @mobile {
    margin-bottom: -370px;
  }
}

.slide-vertical-enter-active, .slide-vertical-leave-active {
  transition: margin-bottom 0.4s ease-out;
}

.slide-vertical-enter-to, .slide-vertical-leave {
  margin-bottom: 0px;
}

.slide-vertical-exams-enter, .slide-vertical-exams-leave-to {
  margin-bottom: -456px;

  @media @mobile {
    margin-bottom: -923px;
  }
}

.slide-vertical-exams-enter-active, .slide-vertical-exams-leave-active {
  transition: margin-bottom 0.4s ease-out;
}

.slide-vertical-exams-enter-to, .slide-vertical-exams-leave {
  margin-bottom: 0px;
}