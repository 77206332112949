
















.content-video {
  max-width: 640px;
  margin: 0 auto;

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
  }

  iframe {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 20px 0;
  }
}
