










@import "../styles/imports.less";

.immersive-navbar {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 15px;

    @media @mobile {
      flex-direction: column;
      align-items: flex-start;

      h4 {
        align-self: center;
        margin-top: 15px;
      }
    }
  }

  /deep/ span {
    display: flex;
    padding: 5px;
  }

  .hide-on-mobile {
    @media @mobile {
        display: none;
    }
  }
}
