



















.login-page {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  height: 100%;
  padding: 0 20px;

  .row {
    display: flex;
    justify-content: center;
    width: 100%;
    flex: 1;
  }
}

.logo {
  width: 150px;
}

.login-container {
  width: 545px;
}

.header {
  padding: 40px 0;

  img {
    margin: 0 auto;
    display: block;
  }
}

.title {
  height: 64px;
  font-family: "Aprova Sans", sans-serif;
  font-size: 24px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: -0.8px;
  text-align: center;
  color: #212121;
  margin-bottom: 2em;
}
