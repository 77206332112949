.line-spacing {
  line-height: 1.5em;
}
.content-text {
  text-align: left;
  word-break: break-word;
  margin: 10px 0;
}
.content-text /deep/ h1 {
  font-size: 32px;
  letter-spacing: -1.2px;
  line-height: 40px;
  color: #212121;
}
.content-text /deep/ h2 {
  letter-spacing: -0.8px;
  line-height: 32px;
  margin: 0px;
  color: #212121;
  font-size: 24px;
  margin: 15px 0;
}
.content-text /deep/ div,
.content-text /deep/ p,
.content-text /deep/ li {
  font-family: "Inter UI", sans-serif;
  font-size: 18px;
  margin: 10px 0;
  color: #4d4d4d;
  line-height: 26px;
  letter-spacing: -0.4px;
}
.content-text /deep/ strong {
  color: #212121;
}
