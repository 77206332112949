.tags {
  display: flex;
  flex-direction: row;

  margin-top: 3px;
  & > * {
    margin-right: 5px;
  }
}

.tag {
  background-color: @color-enem-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  width: fit-content;
  border-radius: 5px;
  .tagText {
    color: white;
    font-size: 14px;
    font-weight: normal;
  }
  &:hover {
    background-color: @color-enem-hover;
  }
}
