


























@import '../../dex-shared/styles/imports.less';

.ListArticle {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;

  .article-content {
    padding-top: 12px;
    flex-grow: 1;

    @media @phone {
      padding-bottom: 40px;
    }
    
    @media @tablet {
      padding-bottom: 52px;
    }
  }

  .report {
    color: #9E9E9E;
    font-family: Aprova Sans;
    font-size: 16px;
    text-align: right;
    cursor: pointer;
  }
}
