
























@import '../../dex-shared/styles/imports.less';

.best-result-title-text {
  text-transform: capitalize;
}

.inside-carousel {
  // TODO: better sizes
  margin: 10px;
  text-align: left;

  @media @tablet {
    width: 210px;
    height: 120px;
  }
  @media @desktop {
    width: 223px;
    height: 160px;
  }
  @media @phone {
    width: calc(50vw - 40px);
    height: calc(1.16 * (50vw - 40px));
  }
  @media @ultrasmall {
    width: calc(100% - 40px);
  }
}

.native-link {
  cursor: pointer;
}

.level-tag-container {
  width: auto; 
  height: auto; 
  background-color: #ECECEC; 
  border-radius: 4px; 
  align-self: flex-end; 
  padding: 4px;
}

.level-tag-text {
  color: #333333; 
  font-size: 10px; 
  line-height: 16px; 
  letter-spacing: -0.4px;
  text-transform: uppercase;
}

.board-card {
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  border-radius: 8px;

  .footer {
    display: block;
  }

  .done-questions {
    font-size: 14px;
    margin-bottom: 4px;
    color: #999999;
  }

  h6 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    max-height: 2.5em;
    margin: 15px 0;
  }
}

.best-result-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  p {
    display: inline-block;

    span {
      color: #212121;
    }
  }
}
