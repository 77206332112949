







































@import '../dex-shared/styles/imports.less';

.search {
  position: relative;
  
  @media @desktop {
    z-index: 1001;
  }
    
  @media @mobile {
    width: 100%;
  }
}

.searchbar-container {
  display: flex;
  align-items: center;
  box-sizing: border-box;

  @media @mobile {
    padding: 10px;
  }
}

.cancel {
  padding: 10px;

  @media @desktop {
    display: none;
  }

  p {
    user-select: none;
    color: #00C974;
    font-size: 14px;
  }
}

.menu-icon {
  padding: 5px;
  padding-right: 10px;
  display: inline-flex;
  box-sizing: border-box;

  @media @desktop {
    display: none;
  }
}

.searchbar {
  display: flex;
  background: #F8F8F8;
  align-items: center;
  padding: 10px;
  min-width: 560px;
  border-radius: 8px;

  @media @mobile {
    min-width: auto;
    flex: 1;
  }

  input {
    background: #F8F8F8;
    border: none;
    outline: none;
    flex: 1;
    font-size: 20px;
    margin: 0 10px;
    -moz-appearance:none;
    -webkit-appearance:none;
    
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      -moz-appearance:none;
      -webkit-appearance:none;
      display: none;
    }

    @media @mobile {
      font-size: 14px;
    }
  }

  /deep/ span {
    display: flex;
  }

  .hidden {
    visibility: hidden;
  }

  &.showRecommendationsBox {
    border-radius: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);

    @media @mobile {
      box-shadow: none;
      border-radius: 8px;
    }
  }
}

.search-recommendations {
  position: absolute;
  width: 100%;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 5;

  @media @mobile {
    box-shadow: none;
  }

  &.not-empty {
    padding-top: 5px;

    @media @desktop {
      padding-top: 15px;
    }
  }

  .results {
    p {
      font-family: Aprova Sans;
      line-height: 16px;
      font-size: 14px;
      letter-spacing: -0.2px;
      color: #666666;
      margin: 10px 20px;
      text-align: left;
    }
  }

  li {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    user-select: none;
    align-items: center;

    .term {
      line-height: 32px;
      font-size: 20px;
      letter-spacing: -0.6px;
      color: #00C974;
    }

    &:hover {
      background: #F8F8F8;
    }
  }
}
