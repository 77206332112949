





















































































@import "../../styles/imports.less";

.dialog-box {
  background-color: white;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
  text-align: start;

  @media @tablet {
    max-width: 320px;
    min-width: 320px;
    border-radius: 8px;
  }

  @media @phone {
    width: 100vw;
    height: 100vh;
  }

  .db-report {
    .db-header {
      display: flex;
      align-items: center;
      padding: 12px 30px;

      /deep/ span {
        display: flex;
        align-self: left;
      }

      p {
        color: #333;
        font-weight: bold;
        margin-left: auto;
        margin-right: auto;
      }

      @media @tablet {
        border-radius: 8px 8px 0px 0px;
      }
    }

    .db-content {
      margin: 15px;

      @media @phone {
        width: 90%;
        margin: 0 auto;
      }

      .subtitle-1 {
        font-weight: bold;
      }

      .text-area {
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        padding: 0 5px;
        width: calc(100% - 10px);
      }

      .db-options {
        margin-bottom: 40px;

        .db-option {
          margin-top: 15px;

          label {
            font-family: "Aprova Sans", sans-serif;
            line-height: 32px;
            font-size: 20px;
            letter-spacing: -0.6px;

            &.capitalize {
              text-transform: capitalize;
            }
          }

          input[type="radio"] {
            display: none;
          }

          input[type="radio"] + label {
            color: #666666;
          }

          input[type="radio"]:checked + label {
            color: #111111;
          }

          input[type="radio"] + label::before {
            margin-right: 10px;
            vertical-align: middle;
            content: url("/img/report/unchecked.svg");
          }

          input[type="radio"]:checked + label::before {
            content: url("/img/report/checked.svg");
          }
        }
      }
    }
  }

  .db-thanks {
    .db-title {
      font-family: "Aprova Sans", sans-serif;
      line-height: 40px;
      font-size: 32px;
      text-align: center;
      letter-spacing: -1.2px;
      color: #333333;
    }

    .db-content {
      display: flex;
      justify-content: center;
      margin: 25px;
    }

    .db-subtitle {
      font-family: "Aprova Sans", sans-serif;
      line-height: 32px;
      font-size: 20px;
      text-align: center;
      letter-spacing: -0.6px;
      color: #666666;
      margin: 0 25px;
    }
  }

  .db-footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px;
  }
}
