











@import "../../dex-shared/styles/imports.less";

.dex-activity-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;

  @media @mobile {
    flex-direction: column;
  }
}

.message {
  margin-right: 50px;

  @media @mobile {
    margin: 0;
  }

  h5 {
    margin: 0;
    margin-bottom: 20px;

    @media @mobile {
      text-align: center;
    }
  }
}

.custom-desktop-only {
  @media @mobile {
    display: none;
  }
}

.custom-mobile-only {
  margin-top: 20px;

  @media @desktop {
    display: none;
  }
}
