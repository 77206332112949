












.full-screen {
  width:100%;
  text-align: center;

  h6 {
    color: #666666;
    margin-bottom: 5vh;
  }

  .center {
    display: table;
    margin: 0 auto;
  }

  .error-image {
    max-width: 100%;
  }
}
