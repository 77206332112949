



































































































































































































































































































































































































































































































































































































































































































































@import (reference) '../dex-shared/styles/imports.less';
.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
	width: 100%;

	.left-side {
		display: flex;
		align-items: center;
		width: 100%;
	}

	.result-button {
		display: flex;
		align-items: center;
		font-family: Aprova Sans;
		line-height: normal;
		font-size: 18px;
		text-align: center;
		letter-spacing: -1px;
		color: #08db88;
		cursor: pointer;

		/deep/ path {
			transition: fill 0.2s ease-out;
		}

		&:hover {
			/deep/ path {
				fill: @color-enem-primary;
			}	
		}
	}
}

.item-name {
  font-weight: bold;
  margin-left: 20px;
}

.focus {
	background-color: @color-reading-bg;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	width: 100%;
	flex-grow: 1;

	.list {
		margin-top: 10px;
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		justify-content: space-between;

		@media @desktop {
			width: 680px;
		}
		@media @mobile {
			width: 90vw;
			margin: 0 auto;
		}

		.question-container {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
      justify-content: center;
			margin-bottom: 100px; // enables scrolling behind nav-buttons
		}
	}
}
.bottom-panel {
	background-color: #ffffff;
	z-index: 1;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.13);
	border-radius: 4px;
	padding: 10px 0;

	.navigation-button {
		margin: 10px;
		max-width: calc((100vw - 40px) / 2);
	}

	.feedback-info-button {
		color: #999999;
		font-size: 20px;
		letter-spacing: -0.8px;
		line-height: 18px;
		border: 0;
	}

	.feedback-skip-button {
		color: #00C974;
		line-height: 18px;
		font-size: 20px;
		text-align: right;
		border: 0;
		letter-spacing: -0.8px;
	}

	.nav-btn {
		user-select: none;

		&.primary {
			color: @color-enem-primary;
		}
	}
}
.c2a {
  padding: 30px 0px;
  @media @phone {
    flex-direction: column;
  }
  .flex {
    display: flex;
    @media @tablet {
      align-items: end;
    }
    @media @phone {
      flex-direction: column;
    }
    h5 {
      @media @tablet {
        margin-left: 15px;
        white-space: nowrap;
      }
      @media @phone {
        font-size: 18px;
      }
    }
  }
  .c2a-text {
    font-family: Aprova Sans;
    letter-spacing: -0.6px;
    color: #616161;
    @media @tablet {
      line-height: 32px;
      font-size: 20px;
    }
    @media @phone {
      margin: 10px 40px;
      text-align: center;
      line-height: 20px;
      font-size: 16px;
    }
  }
}
.results-navigation {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
  .navigation-button {
    margin: 10px;
  }
}
.dex-panel {
  margin-bottom: 40px;
  h5 {
    margin-bottom: 20px;
  }
}
.key-press-tutorial {
  @media @mobile {
    display: none;
  }
  background-color: #fff;
  border-radius: 8px;
  padding: 40px 20px;
  .tutorial-tip {
    display: flex;
    justify-content: space-around;
  }
  h5 {
    max-width: 50%;
    display: inline-block;
    align-self: center;
  }
}

.solution-btn {
  &.from-bottom {
    position: fixed;
    bottom: -100px;
    left: 50%;
    transform: translate(-50%, 0);
    transition: all 0.5s ease-out;
  }

  &.toggle-visible {
    bottom: 64px;
  }
}
