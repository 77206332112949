






.kWidgetIframeContainer {
  height: 350px !important;
}
