//UPDATED COLORS
@blackboard: #212121;
@chalk: #F0F0F0;
@darkGreen: #00C974;
@darkGray: #616161;
@gray: #9E9E9E;
@green: #00E88F;
@lightGreen: #66FFBD;
@lightGray: #E0E0E0;
@white: #FFFFFF;

//ENEM COLORS
@color-enem-face:          @darkGray;
@color-enem-face-title:    @blackboard;
@color-enem-face-subtitle: @darkGray;
@color-enem-face-subtext:  @gray;
@color-enem-button-text:   @blackboard;
@color-enem-call-to-action:@green;
@color-enem-primary:       @green;
@color-enem-auxiliar:      @lightGreen;
@color-enem-auxiliar-2:    @darkGreen;
@color-enem-hover:         @lightGreen;
@color-enem-call:          @darkGreen;
@color-enem-body:          @darkGreen;
@color-enem-bg:            @white;
@color-enem-bg-darker:     #f2f2f2;
@color-enem-wrong:         #DC5A5A;
@color-enem-gold:          #FFE900;
@color-enem-gray:          @lightGray;
@color-enem-correct:       @lightGreen;

@color-reading-bg:         @white;
