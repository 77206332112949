.button {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  height: 32px;
  padding: 0 26px;
  border-radius: 20px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.2px;
  font-weight: bold;
  transition: all 0.3s ease;
}
.button:not(.button-secondary):not(.button-tertiary):not(.button-special):not(.button-disabled) {
  background-color: #00E88F;
  color: #212121;
}
.button:not(.button-secondary):not(.button-tertiary):not(.button-special):not(.button-disabled):hover {
  background-color: #66FFBD;
  transition: 0.3s ease;
}
.button-secondary {
  background-color: white;
  border: 2px solid #00E88F;
  color: #00E88F;
}
.button-secondary:hover {
  background-color: white;
  border-color: #66FFBD;
  color: #66FFBD;
  transition: 0.3s ease;
}
.button-tertiary {
  color: #4D4D4D;
  background-color: #F5F5F5;
}
.button-disabled {
  background-color: #ececec;
  color: #999999;
  cursor: not-allowed;
}
.button-special {
  background-color: white;
  border: 2px solid white;
  color: #212121;
}
.button-special:hover {
  background-color: #FFE900;
  border: 2px solid #FFE900;
}
.button-medium {
  height: 32px;
  padding: 0 20px;
  border-radius: 20px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.2px;
}
.button-large {
  height: 48px;
  padding: 0 36px;
  border-radius: 24px;
  font-size: 16px;
  line-height: normal;
  letter-spacing: -0.6px;
}
.button-xlarge {
  height: 56px;
  padding: 0 36px;
  border-radius: 30px;
  font-size: 20px;
  line-height: 18px;
  letter-spacing: -0.8px;
}
.active {
  background-color: #00E88F;
  color: #FFF;
}
.loading-button {
  cursor: wait;
  background-color: rgba(8, 219, 136, 0.5) !important;
  color: rgba(33, 33, 33, 0.5) !important;
}
.loading-button .dual-ring {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: fit-content;
  margin-left: 8px;
}
.loading-button .dual-ring:after {
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  margin: 1px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #000 transparent #000 transparent;
  animation: dual-ring 1.2s linear infinite;
}
@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
