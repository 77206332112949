.dex-section {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    .dex-content{
        text-align: initial;
        @media @mobile {
            margin: 20px;
            width: calc(100% - 40px);
        }

        @media @desktop {
    
            width: 88%;
            max-width: 1280px; 
        }
    }
}
