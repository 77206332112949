.full-screen {
  width: 100%;
  text-align: center;
}
.full-screen h6 {
  color: #666666;
  margin-bottom: 5vh;
}
.full-screen .center {
  display: table;
  margin: 0 auto;
}
.full-screen .error-image {
  max-width: 100%;
}
