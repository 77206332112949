










@import "../../styles/imports.less";

.report {
  color: #dddddd;
  font-family: "Aprova Sans", sans-serif;
  font-size: 16px;
  text-align: right;
  cursor: pointer;
  user-select: none;
}
