







































@import "../../dex-shared/styles/imports.less";

.sidebar-content {
  @media @desktop {
    position: -webkit-sticky;
    position: sticky;
    top: 26px;
  }
}
