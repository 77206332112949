






.card {
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px;
  transition: 0.3s;
  background-color: white;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);

  &:hover {
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.24);
  }
}

.nav-card {
  cursor: pointer;
}

.no-nav-card {
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
}

.disabled-card {
  opacity: 0.5;
  cursor: not-allowed;
}
