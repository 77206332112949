

















@import '../../dex-shared/styles/imports.less';

.no-decoration {
  height: 100%;
}

.result-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: wrap;
  height: 100%;

  @media @desktop {
    width: 100%;
    flex-grow: 1;
  }

  @media @mobile {
    width: 100%;
  }

  .header {
    display: flex;
    align-items: center;

    .title.wrong {
      margin-left: 5px;
      font-size: 20px;
      font-weight: bold;
      flex-grow: 1;
      color: #dc5a59;
    }
    .title.correct {
      margin-left: 5px;
      font-size: 20px;
      font-weight: bold;
      flex-grow: 1;
      color: #08db88;
      
    }
  }

  .percent {
    color: #212121;
    font-size: 15px;
    letter-spacing: -0.2px;
    margin-top: 0.5em;
    overflow-wrap: break-word;
    font-weight: bold;
  }

  #preview {
    max-height: 5em;
    overflow: hidden;

    /deep/ p { 
      color: #999999;
      font-size: 16px;
      font-weight: normal;
      letter-spacing: -0.4px;
      line-height: 22px;
      max-width: 100%;
    }
  }

  .header,
  .percent,
  #preview {
    width: 100%;
  }

  .no-decoration {
    width: 100%;
  }
} 



