.item-name {
  font-size: 18px;
  letter-spacing: -0.28px;
  line-height: 27px;
  cursor: pointer;
}
.item-name:not(.item-last):not(.board) {
  font-weight: 400;
  color: #999999;
}
.list-section {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
