








































































































































@import "./dex-shared/styles/imports.less";

#app {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  #app-body {
    display: flex;
    flex-grow: 1;
  }

  .immersive-mobile {
    @media @mobile {
      display: none;
    }
  }

  .immersive {
    @media @desktop {
      display: none;
    }
  }
}
