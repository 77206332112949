


























.content-video {
  height: 300px;
  display: flex;
  iframe {
    width: 80%;
    height: 100%;
  }
}
