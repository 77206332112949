














@import "../dex-shared/styles/imports.less";

.history-page {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 60px;
  position: relative;

  .back-nav {
    position: absolute;
    top: 45px;
    left: -80px;
    padding: 5px;

    @media @mobile {
      display: none;
    }
  }

  @media @mobile {
    width: 95%;
  }

  /deep/ .tab-menu {
    margin-bottom: 45px;

    @media @mobile {
      justify-content: flex-start;
    }

    .tab-item {
      text-transform: none;

      .st {
        max-width: unset;
      }
    }
  }
}

/deep/ .inter-body.title {
  color: #212121;
}

/deep/ .inter-body.details {
  font-size: 16px;
  line-height: 16px;
  color: #999;
}

/deep/ .custom-desktop-only {
  @media @mobile {
    display: none !important;
  }
}

/deep/ .custom-mobile-only {
  @media @desktop {
    display: none !important;
  }
}
