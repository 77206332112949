.feedback-container {
  margin-top: 30px;
}
.sidebar-feedback {
  border: 1px solid #e0e0e0;
  padding: 26px;
}
.sidebar-feedback .f-text {
  font-family: Aprova Sans;
  line-height: 16px;
  font-size: 14px;
  letter-spacing: -0.6px;
  color: #666666;
}
.sidebar-feedback .f-c2a {
  font-family: Aprova Sans;
  line-height: normal;
  font-size: 16px;
  letter-spacing: -0.447273px;
  color: #08DB88;
}
.dialog-box {
  background-color: white;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  max-width: 320px;
}
.dialog-box .db-questions .db-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  color: white;
  background-color: #F2994A;
  border-radius: 8px 8px 0px 0px;
  line-height: 16px;
  font-size: 16px;
  letter-spacing: -0.2px;
}
.dialog-box .db-questions .db-input {
  width: calc(100% - 30px);
  padding: 15px;
  height: 8em;
  font-family: Aprova Sans;
  line-height: 18px;
  font-size: 16px;
  color: #212121;
  border: 0px;
  resize: none;
  border-bottom: 1px solid #ECECEC;
}
.dialog-box .db-questions ::placeholder {
  color: #A4A4A4;
}
.dialog-box .db-questions .db-question {
  margin: 15px;
}
.dialog-box .db-questions .db-question .db-text {
  margin-bottom: 5px;
}
.dialog-box .db-questions .db-question .db-buttons {
  display: flex;
}
.dialog-box .db-questions .db-question .db-buttons .db-button {
  margin-right: 20px;
}
.dialog-box .db-thanks .db-header {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
  padding-right: 15px;
}
.dialog-box .db-thanks .db-title {
  font-family: Aprova Sans;
  line-height: 40px;
  font-size: 32px;
  text-align: center;
  letter-spacing: -1.2px;
  color: #333333;
}
.dialog-box .db-thanks .db-content {
  display: flex;
  justify-content: center;
  margin: 25px;
}
.dialog-box .db-thanks .db-subtitle {
  font-family: Aprova Sans;
  line-height: 32px;
  font-size: 20px;
  text-align: center;
  letter-spacing: -0.6px;
  color: #666666;
}
.dialog-box .db-footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}
