



















































































.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
}

.investigations-interactive-data-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.investigation-title-container {
  h4 {
    // text-align: center;
    margin-top: 16px;
    line-height: 30px;
    font-size: 24px;
    letter-spacing: -0.4px;
    color: #d87830;
  }
  h6 {
    text-align: center;
    margin-bottom: 0;
    margin-top: 32px;
    line-height: 25px;
    font-size: 18px;
    color: #999999;
  }
}
