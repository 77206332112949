



































































@import '../dex-shared/styles/media.less';

.exercises-homelink {
  margin-right: 24px;
}

.mobile-routes {
  display: flex;
}

.navbar-divider {
  border-bottom: 1px solid #ececec;
  padding-top: 4px;
  padding-bottom: 4px;
}

.mobile-routes-navigation-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.subtitle-1 {
  font-weight: bold;
  margin-right: 20px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }

  @media @mobile {
    margin-top: 2px
  }
}

.navbar {
  position: relative;

  @media @desktop {
    z-index: 5;
  }
}

.logo {
  height: 35px;
}

.no-desktop{
  @media screen and (min-width: @md-min) {
    display: none;
  }
}

#nav-dex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  background-color: white;

  @media @tablet {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media @phone {
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
  }

  #nav-center {
    display: none;

    @media @desktop {
      display: flex;
      align-items: center;
    }
  }

  #nav-right {
    text-align: right;
    display: flex;
    align-items: center;
  }
}

#button {
  margin: 0 1.5em;
}

.mobile-exercises-navbar {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
}

#profile-picture {
  width: 25px;
  height: 25px;
  margin-bottom: -4px;
}

.button-primary {
  @media @mobile {
    font-size: 14px;
  }
}

.mobile-search {
  @media @desktop {
    display: none;
  }

  main {
    background: #fff;
    height: 100vh;
    width: 100vw;
  }
}

.mobile-icon {
  display: none;

  @media @mobile {
    display: block;
  }
}

.search-nav {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  align-items: center;
  box-sizing: border-box;

  @media @mobile {
    display: inline-flex;
  }
}
