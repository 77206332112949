.modal {
  z-index: 2000;
  position: fixed;
  left: 50vw;
  top: 50vh;
  transform: translate(-50%, -50%);
}
.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
}
