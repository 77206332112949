

























@import "../../dex-shared/styles/imports.less";
.mobile {
  display: none;
  position: fixed;
  top: 0;
  flex-direction: column;
  transition: 0.3s;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto !important;
  background-color: white;

  @media @mobile {
    display: flex;
  }

  @media @phone {
    width: 80%;
  }
  @media @tablet {
    width: 40%;
  }

  .sidebar-content {
    width: 100%;
    box-sizing: border-box;

    .header {
      display: flex;
      flex-direction: row;
      
      .logo {
        margin-left: 20px;
        vertical-align: middle;
      }
    }

    .sub-header {
      display: none;
      margin-top: 10px;
      
      @media @mobile {
        display: block;
      }
    }

    .sidebar-disciplines {
      .link-menu {
        margin-bottom: 5px;
      }
    }
  }
}
