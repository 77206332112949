








































































@import '../../dex-shared/styles/imports.less';

.streak-container {
    display: flex;
    flex-direction: column;
    background-color: green;
    width: 100%;
}

.classroom-header {
    display: flex;
    flex-direction: column;
}
.date-picker {
    width: 100%;
    height: 100px;
    background-color: lightgreen;
    margin-right: 0%;
}

.streak-progress {
    width: 100%;
    height: 325px;
}

.streak-cta {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
}

////////////////////////////////

.slide {
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: green;
    margin-right: 16px;
}

.carousel-item {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.carousel-item-link {
    display: flex;
    flex-direction: column;
    align-items: center;
}

p {
    text-align: center;
    padding-bottom: 2px;
    border-bottom: 2px solid #9E9E9E;
}

.active p {
    font-weight: bold;
    color: #212121;
    border-bottom: 2px solid #00E88F;
}

.streak-fire-signal {
    margin-bottom: 4px;
}

.no-activity-signal {
    width: 20px;
    height: 22px;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0.2;
}

////////////////////////////////////

.consecutives-streaks-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.milestones-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.milestones-carousel-arrows-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
}

.milestones-progress-bar-container {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.milestones-progress-bar {
    width: 100%;
    height: 15px;
    background-color: #F2F2F2;
    border-radius: 100px;
}

.milestones-progress-bar-percentage {
    height: 100%;
    background: linear-gradient(90deg, #FB7B01 10.5%, #F0CA51 37.02%, rgba(240, 202, 81, 0.2) 100%);
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.milestones-breakpoint {
    font-style: normal;
    font-weight: normal;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.6px;
    fill: #999999;
    font-size: 14px;
}

.milestones-active {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: -0.4px;
    fill: #4D4D4D;
}

.milestones-progress-bar-ticks {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.consecutives-streaks-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

///////////////////////////////////////

