











































































@import "../dex-shared/styles/imports.less";

.ListsSection{
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .subsection-header {
    margin-top: 20px;
    @media @desktop {
      margin-left: 20px;
    }
  }

  .lists {
    flex-grow: 1;
    margin-top: 0px;
    background-color: #f8f8f8;
    text-align: center;
    padding-bottom: 40px;
    @media @desktop {
      min-width: calc(100vw - 250px);
      max-width: 100%;
    }
    @media @mobile {
      width: 100vw;
    }

    .dex-content {
      text-align: center;

      .board_section{
        @media @phone {
          margin-bottom: 40px;
        }
      }

      h4 {
        text-align: left;
        margin-bottom: 10px;
      }

      h6 {
        text-align: left;
        color: #999999;
        font-weight: normal;
      }
      .cards {
        text-align: left;
        position: relative;

        .card-single {
          margin-top: 1em;
          display: flex;

          &::before{
            content: "";
            padding-bottom: 56.25%;
            top:0;
          }

          .box{
            height: 100%;
            width: 100%;
          }
        }

      }
      .justify {
        @media @mobile {
          justify-content: space-evenly;
        }
      }
    }

  }


  .call-to-action {

    .c2a-header{
      display: flex;
      max-width: 100vw;

      @media @desktop{
        margin-top: 20px;
      }

      @media @phone {
        flex-direction: column;
      }

      h5 {
        margin-left: 0px;
        max-inline-size: 15em;
        text-align: left;
      }
    }

    .c2a-content{
      text-align: left;
      width: 100%;
      margin: 0px;
      margin-top: 40px;

      .caption{
        font-family: "Aprova Sans";
        font-size: 18px;
        font-weight: bold;
        letter-spacing: -0.25px;
        line-height: 20px;
        color: #212121;
        margin-left: 15px;
      }
    }
  }

  .c2a-img {
    margin-top: 15px;
  }

}

