.consecutives-streaks-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.milestones-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.milestones-carousel-arrows-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 95%;
}
.milestones-progress-bar-container {
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
