






































@import '../../dex-shared/styles/imports.less';
.classroom {
  @media @desktop {
    max-width: calc(100vw - 250px);
    margin-right: -20px;
    margin-left: -10px;
    margin-bottom: 20px;
  }
  @media @mobile {
    max-width: calc(100vw - 40px);
    .navigation {
      margin-top: 0px;
    }
  }
}

.navigation {
  display: flex;
  justify-content: flex-end;
  height: 48px;
  margin-right: 10px;
  margin-top: -48px;
  .material-design-icon__svg {
    cursor: pointer;
    height: 48px !important;
    width: 48px !important;
  }
}

.board {
  .navigation {
    display: none;
  }
}

.call-to-action {
  max-width: 100%;
  overflow: hidden;
}
