































































































@import '../dex-shared/styles/imports.less';

.search-page {
  width: 100%;
  background: #F2F2F2;

  .results-container {
    width: 100%;
  }

  .container {
    width: 100%;
  }

  .no-results {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 0 70px;

    .body {
      margin-top: 40px;
    }
  }

  .result-overview {
    background: #fff;
    padding: 15px 70px;
    border: 1px solid #E0E0E0;
    margin-left: -1px;
    margin-top: -1px;

    @media @mobile {
      padding: 15px 30px;
    }

    h6 {
      color: #999999;

      span {
        color: #212121;
      }
    }
  }

  .results {
    padding: 20px 70px;

    @media @phone {
      padding: 20px;
    }

    h6 {
      margin: 40px 0 15px 0;

      &.best-result {
        margin-top: 0;
      }
    }

    div.best-result {
      display: inline-flex;
      width: 90%;
      max-width: 600px;
      height: 200px;

      @media @phone {
        width: 100%;
      }
    }
  }

  .cards {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    .card-single {
      display: flex;
      max-height: 200px;
      box-sizing: border-box;
      flex: 0 0 23%;
      margin-right: 10px;

      @media only screen and (max-width: 1100px) {
        flex: 0 0 33%;
      }

      @media only screen and (max-width: 900px) {
        flex: 0 0 45%;
      }

      @media only screen and (max-width: 600px) {
        flex: 1 0 51%;
      }

      .box {
        height: 100%;
        width: 100%;
      }
    }
  }
}

