




















































































@import "../dex-shared/styles/imports.less";
    div.container-dropdown {
        width: auto;
        height: 175px;
        display: flex;
        flex-direction: column;
        margin-top: 28px;
    }

    .custom-select-modal {
        width: 100vw;
        height: 100vh;
        background: #fff;

        header {
            margin: 0 15px;
            nav {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 15px;

                /deep/ span {
                    display: flex;
                }
            }
        }

        .options {
            background: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 20px;

            .option {
                padding: 10px 15px;
            }
        }
    }

    .option-radius-container {
        width: 20px;
        height: 20px;
        border: 1.2px solid #6D6D6D;
        border-radius: 2px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mobile-option-container {
        margin-top: 16px !important;
        border-bottom: 1px solid #E0E0E0;
        padding-left: 32px;
        padding-right: 32px;
        margin-left: 24px;
        margin-right: 24px;
        padding-bottom: 4px;
    }

    .custom-mobile-only {
        @media @desktop {
            display: none;
        }
    }

    .custom-desktop-only {
        @media @mobile {
            display: none;
        }

        @media @tablet {
            display: none;
        }
    }

    .option-radius-inner-box {
        width: 10px;
        height: 10px;
        border-radius: 1px;
        background: #00E88F;
        transition: 0.5s ease;
    }

    a {
        cursor: pointer;
    }

    div.options-box {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    div.dropdown-header {
        width: auto;
        display: flex;
        flex-direction: row;
        transition: 0.25s ease;
        padding: 8px;
    }

    img.header-arrow {
        margin-left: 8px;
    }

    .option-text {
        font-family: Aprova Sans;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.6px;
        color: #6D6D6D;
        text-transform: capitalize;
    }

    h6.header-content {
        font-family: Aprova Sans;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.4px;
        color: #6D6D6D;
        transition: 0.25s ease;
    }

    h6.header-content:hover {
        color: #00C974;
        transition: 0.25s ease;
    }

    .active {
        h6 {
            transition: 0.25s ease;
            color: #00C974;
        }
        &.dropdown-header {
            background-color: #F8F8F8;
            transition: 0.25s ease;
            border-radius: 8px 8px 0px 0px;
        }
    }

    div.dropdown-body {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.13);
        border-radius: 0px 0px 8px 8px;
        background: #FFFFFF;
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    div.option {
        display: flex;
        flex-direction: row;
        margin-top: 4px;
        margin-bottom: 4px;
    }

    div.option-content {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

