.card:hover {
  padding: 9px 9px;
  border: 2px solid #333333;
}
.list-group-item {
  border: solid 1px #ececec;
}
.logged-out {
  opacity: 0.7;
  cursor: default !important;
}
