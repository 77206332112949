













@import "../../styles/imports.less";

.descomplica-logo {
  width: 24px;
}
