













@import "../styles/imports.less";

textarea {
  font-family: "Inter UI", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.4px;
  color: #616161;
  border: none;
  outline: none;
  width: 100%;
  padding: 0;

  &::placeholder {
    color: #9e9e9e;
  }
}
