










@import "../styles/imports.less";

.ds-loading {
  text-align: center;
  display: block;
  padding: 40px 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  .ds-loading-line {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    margin-right: 8px;
    //background-color: #4b9cdb;
    background-color: @color-enem-primary;

    &:nth-last-child(1) {
      animation: loading 0.6s 0.1s linear infinite;
    }
    &:nth-last-child(2) {
      animation: loading 0.6s 0.2s linear infinite;
    }
    &:nth-last-child(3) {
      animation: loading 0.6s 0.3s linear infinite;
    }
    &:nth-last-child(4) {
      animation: loading 0.6s 0.4s linear infinite;
    }
    &:nth-last-child(5) {
      animation: loading 0.6s 0.5s linear infinite;
    }
    &:nth-last-child(6) {
      animation: loading 0.6s 0.6s linear infinite;
    }
  }

  .ds-loading-text-loading {
    display: block;
    text-align: center;
    font-size: 22px;
    font-weight: 200;
  }

  @keyframes loading {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, 15px);
    }
    100% {
      transform: translate(0, 0);
    }
  }
}

.partial-loading {
  position: relative;
  left: auto;
  transform: none;
}
