.slide {
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.carousel-item {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.carousel-item-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}
p {
  text-align: center;
  padding-bottom: 2px;
  border-bottom: 2px solid #9E9E9E;
}
.active p {
  font-weight: bold;
  color: #212121;
  border-bottom: 2px solid #00E88F;
}
.streak-fire-signal {
  margin-bottom: 4px;
}
.no-activity-signal {
  width: 20px;
  height: 22px;
}
