




























#sidebar {
  .after-disciplines-c2as {
    margin-top: 30px;

    .c2a {
      display: flex;
      align-items: center;
      width: 100%;
      text-decoration: none;

      .c2a-icon {
        padding: 5px 0;
        margin-right: 10px;
        margin-left: -3px;
        font-size: unset;
        display: flex;

        svg {
          bottom: unset !important;
          height: 18px;
        }

        path {
          fill: #666666;
        }
      }

      &:hover {
        p {
          color: #00e98b;
          transition: all 0.3s ease;
        }

        .c2a-icon {
          path {
            fill: #00e98b;
            transition: all 0.3s ease;
          }
        }
      }
    }
  }
}
