.list-group.objective-alternatives,
.list-group.ab-tracking-alternatives {
  cursor: default;
  color: #616161;
}
.list-group.objective-alternatives .alternative-hint,
.list-group.ab-tracking-alternatives .alternative-hint {
  color: #999999;
  font-size: 14px;
  padding-left: 10px;
}
.list-group.objective-alternatives .alternative-id,
.list-group.ab-tracking-alternatives .alternative-id {
  margin-right: 1em;
  font-weight: bold;
}
.list-group.objective-alternatives .alternative-id.correct,
.list-group.ab-tracking-alternatives .alternative-id.correct {
  color: #66FFBD;
}
.list-group.objective-alternatives .alternative-id.wrong,
.list-group.ab-tracking-alternatives .alternative-id.wrong {
  color: #DC5A5A;
}
.list-group.objective-alternatives .list-group-item,
.list-group.ab-tracking-alternatives .list-group-item {
  max-width: 768px;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 8px;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
  transition: 0.3s ease;
  cursor: pointer;
}
.list-group.objective-alternatives .list-group-item:hover:not(.logged-out),
.list-group.ab-tracking-alternatives .list-group-item:hover:not(.logged-out) {
  color: #212121;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid #ffffff;
}
.list-group.objective-alternatives .list-group-item .alternative-icon,
.list-group.ab-tracking-alternatives .list-group-item .alternative-icon {
  display: flex;
  margin-left: auto;
}
.list-group.objective-alternatives .list-group-item .content-text,
.list-group.ab-tracking-alternatives .list-group-item .content-text {
  width: 100%;
  flex-grow: 1;
}
.list-group.objective-alternatives .result,
.list-group.ab-tracking-alternatives .result {
  cursor: default !important;
  border-color: #eee;
}
.list-group.objective-alternatives .foo-class,
.list-group.ab-tracking-alternatives .foo-class {
  color: none;
}
.list-group.objective-alternatives .correct,
.list-group.ab-tracking-alternatives .correct {
  border: solid 2px #333333;
  color: #212121;
}
.list-group.objective-alternatives .checked,
.list-group.ab-tracking-alternatives .checked {
  color: #212121;
}
.list-group.objective-alternatives .unchecked,
.list-group.ab-tracking-alternatives .unchecked {
  opacity: 0.4;
}
.list-group.objective-alternatives .correct.result,
.list-group.ab-tracking-alternatives .correct.result {
  transition: 2.1s;
  background-color: white;
  border: solid 2px #66FFBD;
  padding: 9px 9px;
}
.list-group.objective-alternatives .correct.result.checked,
.list-group.ab-tracking-alternatives .correct.result.checked {
  animation-fill-mode: forwards;
  animation-name: animation-correct;
  animation-duration: 2.1s;
}
@keyframes animation-correct {
  0%,
  25% {
    background-color: #66FFBD;
    border: solid 2px #66FFBD;
  }
}
.list-group.objective-alternatives .wrong.result.checked,
.list-group.ab-tracking-alternatives .wrong.result.checked {
  animation-fill-mode: forwards;
  animation-name: animation-wrong;
  animation-duration: 2.1s;
  padding: 9px 9px;
  opacity: 0.8;
}
@keyframes animation-wrong {
  0%,
  25% {
    background-color: #DC5A5A;
    border: solid 2px #DC5A5A;
  }
  75%,
  100% {
    background-color: white;
    border: solid 2px #DC5A5A;
  }
}
.list-group.objective-alternatives .blank.result,
.list-group.ab-tracking-alternatives .blank.result {
  opacity: 0.4;
  padding: 9px 9px;
}
.list-group.objective-alternatives .correct.list-group-item .alternative-icon,
.list-group.ab-tracking-alternatives .correct.list-group-item .alternative-icon {
  color: #00E88F;
  font-weight: bold;
}
.list-group.objective-alternatives .correct.list-group-item .alternative-icon .content-text,
.list-group.ab-tracking-alternatives .correct.list-group-item .alternative-icon .content-text {
  font-weight: bold !important;
}
.list-group.objective-alternatives .wrong.list-group-item .alternative-icon,
.list-group.ab-tracking-alternatives .wrong.list-group-item .alternative-icon {
  color: #DC5A5A;
  font-weight: bold;
}
.list-group.objective-alternatives .wrong.list-group-item .alternative-icon .content-text,
.list-group.ab-tracking-alternatives .wrong.list-group-item .alternative-icon .content-text {
  font-weight: bold !important;
}
.list-group.objective-alternatives .list-group-item.dark-border.correct,
.list-group.ab-tracking-alternatives .list-group-item.dark-border.correct {
  border-color: #333 !important;
}
.list-group.objective-alternatives .material-design-icon,
.list-group.ab-tracking-alternatives .material-design-icon {
  font-size: 1.5em;
}
