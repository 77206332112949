










@import "../styles/imports.less";

.tab-menu {
  margin-top: 20px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;

  @media @phone {
    justify-content: space-between;
  }

  .tab-item {
    text-transform: uppercase;

    @media @desktop {
      font-size: 18px;
      margin-right: 20px;
    }

    @media @mobile {
      margin-right: 10px;
    }

    @media @phone {
      font-size: 14px;
      margin-right: 5px;
    }

    .st-active {
      transition: 0.3s ease;
      font-weight: bold;
      color: #212121;
      border-bottom: 4px solid @color-enem-primary;
    }

    .st {
      text-align: center;
      height: 28px;
      margin-bottom: 30px;
      transition: 0.3s;
      display: table-cell;
      vertical-align: middle;
      letter-spacing: -0.6px;

      @media @desktop {
        width: 180px;
      }

      @media @tablet-strict {
        width: 180px;
      }

      @media @phone-strict {
        min-width: 100px;
        max-width: 100px;
        margin: 0;
      }
    }

    .st:hover {
      transition: 0.3s ease;
      border-bottom: 4px solid @color-enem-hover;
    }

    .st:not(.st-active):hover {
      transition: 0.3s ease;
      border-bottom: 4px solid @color-enem-hover;
    }

    .st:not(.st-active) {
      transition: 0.3s ease;
      color: #999999;
      border-bottom: 4px solid #ececec;
      font-weight: normal;
    }
  }
}
