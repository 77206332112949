



















































@import (reference) "../../../dex-shared/styles/imports.less";

.list-group.objective-alternatives, .list-group.ab-tracking-alternatives {
  cursor: default;
  color: @color-enem-face;

  .alternative-hint {
    color: #999999;
    font-size: 14px;
    padding-left: 10px;
  }

  .alternative-id {
    margin-right: 1em;
    font-weight: bold;
  }
  .alternative-id.correct {
    color: @color-enem-correct;
  }
  .alternative-id.wrong {
    color: @color-enem-wrong;
  }

  .list-group-item {
    max-width: 768px;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 8px;
    border-radius: 4px;
    border: 1px solid #f2f2f2;
    transition: 0.3s ease;
    cursor: pointer;
    
    &:hover:not(.logged-out) {
      color: #212121;
      box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.12);
      border: 1px solid #ffffff;
    }

    .alternative-icon {
      display: flex;
      margin-left: auto;
    }

    .content-text {
      width: 100%;
      flex-grow: 1;
    }
  }

  .result {
    cursor: default !important;
    border-color: #eee;
  }

  .foo-class {
    color: none;
  }

  .correct {
    border: solid 2px #333333;
    color: #212121;
  }

  .checked {
    color: #212121;
  }

  .unchecked {
    opacity: 0.4;
  }

  .correct.result {
    &.checked{
      @keyframes animation-correct {
        0%,25% {
          background-color: @color-enem-correct;
          border: solid 2px @color-enem-correct;
        }
        75%,100% {
        }
      }
      animation-fill-mode: forwards;
      animation-name: animation-correct;
      animation-duration: 2.1s;
    }
    transition: 2.1s;
    background-color: white;
    border: solid 2px @color-enem-correct;
    padding: 9px 9px;
  }

  .wrong.result.checked {
    @keyframes animation-wrong {
      0%,25% {
        background-color: @color-enem-wrong;
        border: solid 2px @color-enem-wrong;
      }
      75%,100% {
        background-color: white;
        border: solid 2px @color-enem-wrong;
      }
    }
    animation-fill-mode: forwards;
    animation-name: animation-wrong;
    animation-duration: 2.1s;
    padding: 9px 9px;
    opacity: 0.8;
  }

  .blank.result {
    opacity: 0.4;
    padding: 9px 9px;
  }

  .correct.list-group-item .alternative-icon {
    color: @color-enem-primary;
    .content-text {
      font-weight: bold !important;
    }
    font-weight: bold;
  }

  .wrong.list-group-item .alternative-icon {
    color: @color-enem-wrong;
    .content-text {
      font-weight: bold !important;
    }
    font-weight: bold;
  }

  .list-group-item.dark-border.correct {
    border-color: #333 !important;
  }

  .material-design-icon {
    font-size: 1.5em;
  }
}

