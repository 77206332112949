@import './media.less';
@import './colors.less';

h1 { //Destaques
  font-family: "Aprova Sans", sans-serif;
  font-size: 72px;
  font-weight: 900;
  letter-spacing: -2.4px;
  line-height: 80px;
  color: @blackboard;
}

h2 {
  font-family: "Aprova Sans", sans-serif;
  font-size: 60px;
  letter-spacing: -2px;
  line-height: 68px;
  color: @blackboard;
}

h3 { //Títulos de página
  font-family: "Aprova Sans", sans-serif;
  color: @blackboard;
  font-size: 48px;
  letter-spacing: -1.6px;
  line-height: 56px;
  @media @phone {
    font-size: 32px;
    letter-spacing: -1.2px;
    line-height: 40px;
  }
}

h4 { 
  font-family: "Aprova Sans", sans-serif;
  font-size: 32px;
  letter-spacing: -1.2px;
  line-height: 40px;
  color: @blackboard;
}

h5 { //Títulos de seções
  font-family: "Aprova Sans", sans-serif;
  letter-spacing: -0.8px;
  line-height: 32px;
  margin: 0px;
  color: @blackboard;
  @media @tablet {
    font-size: 24px;
  }
  @media @phone {
    font-size: 24px;
  }
}

h6 { //Título dos cards
  font-family: "Aprova Sans", sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.6px;
  line-height: 24px;
  margin: 0;
  color: @blackboard;
}

p, li {
  font-family: "Aprova Sans", sans-serif;
  font-size: 16px;
  margin: 0;
  color: @darkGray;
}

.body {
  font-family: "Aprova Sans", sans-serif;
  letter-spacing: -0.6px;
  margin: 0;
  color: @darkGray;

    @media @desktop {
      font-size: 20px;
      line-height: 32px;
    }

    @media @mobile {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.6px;
    }
}

.subtitle-1 {
  font-family: "Aprova Sans", sans-serif;
  font-size: 16px;;
  line-height: 24px;
  letter-spacing: -0.4px;
  color: @darkGray;
}

.subtitle-2 {
  font-family: "Aprova Sans", sans-serif;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.2px;
  color: @darkGray;
}

.card-subtitle {
  font-family: "Aprova Sans", sans-serif;
  font-size: 18px;
  margin: 0;
  color: @gray;
}

.question-text {
  font-family: "Aprova Sans", sans-serif;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: -0.4px;
  line-height: 25px;
  color: @darkGray;
}

#app-body .question-small {
  .content-text p {
    font-family: "Inter UI", sans-serif;
    font-size: 24px;
    font-weight: normal;
    letter-spacing: -0.4px;
    line-height: 30px;
    color: @darkGray;
  }
}

#app-body .question-medium {
  .content-text p {
    font-family: "Inter UI", sans-serif;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: -0.6px;
    line-height: 28px;
    color: @darkGray;
  }
}

#app-body .question-large {
  .content-text p {
    font-family: "Inter UI", sans-serif;
    font-size: 18px;
    font-weight: normal;
    letter-spacing: -0.6px;
    line-height: 28px;
    color: @darkGray;
  }
}

.align-center {
  text-align: center;
}

.link-menu {
  font-family: "Aprova Sans", sans-serif;
  font-size: 16px;
  color: @darkGreen;
  line-height: 2em;

  &:hover {
    color: @lightGreen;
    transition: all 0.3s ease;
  }
}

.link {
  font-family: "Aprova Sans", sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: @darkGreen;
  line-height: 2em;
  letter-spacing: -0.45px;

}
.tag {
  font-family: "Aprova Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: @gray;
}

.caption {
  font-family: "Aprova Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: -0.3px;
  line-height: 20px;
  color: @gray;
}

.extra-caption {
  font-family: "Aprova Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: -0.21875px;
  color: @gray;
}

input {
  font-family: "Aprova Sans", sans-serif;
}

textarea {
  font-family: "Aprova Sans", sans-serif;
}

input.textInput,
textarea,
input.vdatetime-input,
select.textSelect {
  padding: 10px 15px 10px 15px !important;
  border: 1px solid @color-enem-gray;
  border-radius: 8px;
  font-size: 16px;
}

input.vdatetime-input {
  margin-top: 6px;
}

.inter-body {
  font-family: "Inter UI", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.4px;
  color: @darkGray;
}

.h3-global-title {
  margin-right: 20px;
}

.global-clickable {
  font-size: 30px;
  margin-top: 5px;
}