


































































































































@import "../../dex-shared/styles/imports.less";

.dex-adblock-modal {
  background: #fff;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
}

.message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 15px;

  @media @desktop {
    height: 100%;
  }
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 450px;
  text-align: center;

  p {
    margin: 30px 0 40px 0;
  }

  h4 {
    margin-top: 0;
  }
}
