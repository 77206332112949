














@import "../dex-shared/styles/imports.less";
  .post-mortem-container {
  width: 100%; 
  height: 120px; 
  background: #FFF; 
  position: fixed; 
  bottom: 0; 
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-top: 1px solid #CCC;
  transition: opacity 2s linear;

  @media @mobile {
    justify-content: center;
  }

}

.post-mortem-content-container {
  h5 {
    margin-bottom: 16px;
  }

  @media @mobile {
    margin-left: 8px;
  }
}

.close-icon-container {
  margin-top: 12px; 
  align-self: flex-start;
}

.close-icon {
    margin-top: 16px;
    cursor: pointer;
    @media @mobile {
      margin-right: 32px;
    }
}

.vanish {
  transition: opacity 2s linear;
  display: none;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
