













































































@import "../../dex-shared/styles/imports.less";

.activity-day {
  header {
    display: flex;
    justify-content: space-between;

    .day {
      h5 {
        color: #212121;
      }
    }
  }

  .history-items {
    .history-item-container:first-of-type {
      margin-top: 40px;
    }

    .status.custom-mobile-only {
      flex-basis: 100%;
      width: 100%;
    }

    .show-more {
      margin: 0 auto;
      margin-top: 30px;
    }

    .history-item {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;

      .left {
        min-width: 90px;
        max-width: 150px;
        margin-right: 10px;
        position: relative;

        @media @mobile {
        min-width: 20px;
        max-width: 50px;
        }
      }

      .status {
        display: flex;
        align-items: center;
        margin-top: 5px;

        /deep/ span {
        display: flex;
        margin-right: 5px;
        }
      }

      .vertical-line {
        position: absolute;
        height: calc(100% - 24px);
        width: 0;
        border-right: 1px solid #e0e0e0;
        left: 8px;
        top: 26px;

        @media @mobile {
          top: 3px;
          height: calc(100%);
        }
      }

      .right {
        flex: 1;
        padding-bottom: 60px;

        @media @mobile {
          padding-top: 16px;
        }

        header {
          align-items: center;

          p.body {
            color: #212121;
          }
        }

        &.no-padding {
          padding-bottom: 0;
        }

        .go-to-list {
          border-radius: 50%;
          width: 35px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          text-decoration: none;
          color: #6D6D6D;

          a {
            color: inherit;
          }

          /deep/ span {
            display: flex;
            transform: rotate(45deg);
          }

          &:hover {
            background-color: @color-enem-bg;

            /deep/ span {
              color: #00e88f;
            }
          }
        }
      }

      /deep/ .progress-bar {
        margin-top: 10px;

        .bar-section {
        height: 6px;
        }
      }
    }
  }
}
