.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 120px;
}
.foo-enter {
  opacity: 0;
}
.foo-enter-active {
  transition: opacity 0.9s;
}
.foo-leave-active {
  transition: opacity 0s;
  opacity: 0;
}
.doubt-container {
  width: 60%;
}
.doubt-title {
  margin-bottom: 32px;
  margin-top: 32px;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.doubt-title h5 {
  text-align: center;
  line-height: 32px;
  letter-spacing: -0.6px;
  color: #999999;
  margin-top: 16px;
}
.doubt-title img {
  width: 50px;
  height: 50px;
}
.doubt-main-message {
  text-align: center;
  line-height: 30px;
  letter-spacing: -0.4px;
  color: #212121;
  margin-top: 32px;
  margin-bottom: 32px;
}
.doubt-main-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}
.doubt-paragraph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}
.doubt-paragraph {
  font-size: 18px;
  text-align: center;
  line-height: 32px;
  letter-spacing: -0.6px;
  color: #4d4d4d;
  font-weight: lighter;
}
.interactive-data-doubt-container {
  margin-top: 85px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
