


















  @import '../dex-shared/styles/imports.less';

  .classroom-header {

    @media @tablet {
      display: flex;
    }

    @media @phone {
        display: flex;
    }

    @media @desktop {
      display: none !important;
    }


  flex-direction: row;
  justify-content: space-evenly;

  .light-text {
    font-size: 14px;
    color: #999999;
  }

  h1 {
    text-align: left;
    font-size: 15vh;
    font-weight: bold;
    color: #333333;
    margin-top: 40px;
  }

  .mobile-header {
    position: absolute;
    right: 0;
    padding: -20px;
  }

  .classroom-img {
    height: 321px;
    padding: 10px;
  }

  .provas {
    max-width: 500px;
    display: flex;

    .tri-prova {
      width: 200px;
    }
  }

  .logged-out {
    max-width: 400px;
    text-align: left;

    .lo-subtext {
      max-width: 400px;
      margin-bottom: 20px;
    }
  }

.subtext {
  font-size: 14px;
  color: #999999;
  padding-left: 8px;
 }
}

.logged-out {
    max-width: 400px;
    text-align: left;

    .lo-subtext {
      max-width: 400px;
      margin-bottom: 20px;
    }
  }
