

























































@import "../dex-shared/styles/imports.less";


.classroom-content {
  display: flex;
  flex-direction: column; 
  flex-grow: 1;
  
  
  .classroom-header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .light-text {
      font-size: 14px;
      color: #999999;
    }

    h1 {
      text-align: left;
      font-size: 15vh;
      font-weight: bold;
      color: #333333;
      margin-top: 40px;
    }

    .mobile-header {
      position: absolute;
      right: 0;
      padding: -20px;
    }

    .classroom-img {
      height: 321px;
      padding: 10px;
    }

    .provas {
      max-width: 500px;
      display: flex;

      .tri-prova {
        width: 200px;
      }
    }

    .logged-out {
      max-width: 400px;
      text-align: left;

      .lo-subtext {
        max-width: 400px;
        margin-bottom: 20px;
      }
    }
 
  .subtext {
    font-size: 14px;
    color: #999999;
    padding-left: 8px;
   }
  } 
} 

   .classroom-lists {
    /* margin: -20px;*/ 
      margin-top: 40px;
      @media @desktop {
        padding: 20px;
      }
      background-color: #f8f8f8;
      flex-grow: 1;

      .list-section {
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 8px;
        margin-top: 8px;
      }
    }

  .pwa-banner {
    margin: 40px 0 60px 0;
  }

