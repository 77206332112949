











@import "../styles/imports.less";

.speech-bubble-menu {
  position: relative;
  background: #fff;
  min-width: 100px;
  min-height: 1px;
  box-sizing: border-box;
  border: 1px solid #ececec;
  border-radius: 2px;

  &:after {
    content: "";
    border: 10px solid transparent;
    border-bottom-color: #fff;
    border-top: 0;
    position: absolute;
    top: -8px;
    right: 10px;
  }

  .arrow {
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: #ececec;
    border-top: 0;
    position: absolute;
    top: -10px;
    right: 10px;
  }
}

.speech-bubble-menu.darkmode {
  background-color: #4F4F4F;
  color: #ffffff;
  &:after {
    border-bottom-color: #4F4F4F;
  }
}
