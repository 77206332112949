


























































@import '../../dex-shared/styles/imports.less';

.ListArticle { 
  .article-content {
    .content-text {
      text-align: left;
      word-break: break-word;
      margin: 10px 0;

      h1 {
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -1.2px;
        line-height: 40px;
        color: #212121;
        margin: 0 0 44px 0;
      }

      h2 {
        letter-spacing: -0.8px;
        line-height: 32px;
        margin: 0px;
        color: #212121;
        font-size: 24px;
        margin: 0 0 36px 0;
      }

      h3 {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.6px;
        margin: 0px;
        color: #616161;
        margin: 0 0 32px 0;
      }

      div,
      p,
      li {
        font-family: "Inter UI", sans-serif;
        font-size: 18px;
        margin: 0;
        color: #4d4d4d;
        line-height: 28px;
        letter-spacing: -0.4px;
      }

      p,
      div {
        margin: 0 0 32px 0;
        text-align: justify;
        hyphens: auto;
      }

      br {
        display: block;
        margin: 0 0 8px 0; 
        line-height: 8px;
        content: " "; 
      }

      p,
      div {
        br {
          margin: 0 0 16px 0; 
          line-height: 16px;
        }
      }

      strong {
        color: #212121;
      }

      a { 
        color: @color-enem-body;
        text-decoration: none;
      }
    }
  }
}
