

















































































@import "../../dex-shared/styles/imports.less";

.dex-pwa-modal {
  background: #fff;
  height: 100vh;
	width: 100vw;
	overflow-y: auto;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
  margin: 0 20px;
  border-bottom: 1px solid #e0e0e0;

  div {
    flex: 1;
  }

  @media @mobile {
    padding: 10px 20px;
  }

  div.left {
    margin-right: auto;

    .back-icon {
      padding: 5px;
      font-size: 26px;
      display: flex;

      /deep/ svg {
        bottom: unset !important;
      }

      /deep/ path {
        fill: #666666;
      }
    }
  }

  div.center {
    p {
      text-align: center;
    }
  }

  div.right {
    margin-left: auto;
  }
}

.common-instructions {
  text-align: center;
  width: 70%;
  margin: 0 auto;
  margin-top: 15px;

  @media @mobile {
    width: 90%;
  }

  p {
    color: #999999;

    &.body {
			color: #6D6D6D;
			font-size: 18px;
    }
  }

  h5 {
    margin: 20px 0;
  }
}

.custom-desktop-only {
  @media @mobile {
  display: none;
  }
}

.custom-mobile-only {
  @media @desktop {
  display: none;
  }
}

/deep/ .tab-menu {
  justify-content: center;
}

.browser-instructions {
	display: flex;
	justify-content: space-around;
	margin-top: 50px;
	padding: 0 30px;

	@media @mobile {
		flex-direction: column;
		justify-content: center;
	}

	.step {
		flex-basis: 20%;
		text-align: center;

		@media @mobile {
			border-bottom: 1px solid #e0e0e0;
			padding-bottom: 20px;
			margin-bottom: 20px;
			margin-top: 10px;
		}

		&:last-child {
			border-bottom: none;
		}

		p.subtitle-1 {
			color: #999999;
			margin-bottom: 10px;
		}

		h6, p.bottom-instruction {
			color: #6D6D6D;
		}

		img {
			margin: 22px 0;
			max-width: 230px;

			&.stroked {
				border: 1px solid #e0e0e0;
				box-sizing: border-box;
				border-radius: 8px;
			}
		}
	}

	&.others {
		width: 70%;
		margin: 0 auto;
		margin-top: 50px;
		text-align: center;
		flex-direction: column;
		align-items: center;

		@media @mobile {
			width: 90%;
			padding: 0;
		}

		h5 {
			text-align: center;
			margin-bottom: 30px;

			@media @desktop {
				display: none;
			}
		}

		h6 {
			border-bottom: 1px solid #e0e0e0;
			margin-top: 20px;
			width: 100%;
			text-align: left;

			@media @mobile {
				display: none;
			}
		}

		div.browsers {
			display: flex;
			width: 100%;
			justify-content: space-around;
			margin-top: 30px;
			flex-wrap: wrap;

			@media @mobile {
				justify-content: flex-start;
				margin-bottom: 20px;
			}

			img {
				@media @mobile {
					margin-right: auto;
					margin-bottom: 10px;
				}
			}
		}
	}
}

.close-button {
	margin: 0 auto;
	margin-bottom: 40px;

	@media @desktop {
		margin-top: 60px;
	}
}
