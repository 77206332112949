










































@import '../../dex-shared/styles/imports.less';

.ListQuestion {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  background: transparent;

  .question-content {
    margin: 30px 0;
  }

  .report {
    color: #dddddd;
    font-family: Aprova Sans;
    font-size: 16px;
    text-align: right;
    cursor: pointer;
    margin-top: 15px;
  }

  &.dex-panel {
    padding: 0;
  }
}

