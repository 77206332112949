











































































































































































































































































.question-map-carousel {
  .nav-button {
    .material-design-icon__svg {
      cursor: pointer;
      height: 48px !important;
      width: 48px !important;
    }
  }
}

.qm-carousel {
  .VueCarousel-inner {
    align-items: center;
  }
}
