










































































































@import "../../dex-shared/styles/imports.less";

.section-title {
  margin-bottom: 40px;
}

.boards-container {
  display: flex;
  flex-wrap: wrap;
}

.totals {
  display: flex;
  align-items: flex-start;
  margin-bottom: 40px;

  section {
    flex-basis: 35%;

    @media @phone {
      flex-basis: 100%;
    }

    h4 {
      margin: 0;   
    }
  }
}

.board {
  display: inline-flex;
  user-select: none;
  flex-basis: 33%;
  margin-bottom: 20px;

  @media @tablet-strict {
    flex-basis: 50%;
  }

  @media @phone {
    flex-basis: 100%;
  }

  .board-info {
    margin-left: 15px;
  }
}

.legend {
  .open-legend {
    display: flex;
    user-select: none;
    margin-top: 15px;
    align-items: center;

    /deep/ span {
      margin-left: 3px;
      display: flex;
    }
  }

  .boards-legend {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .legend-title {
      font-size: 14px;
      color: #616161;
    }

    section {
      flex-basis: 33%;
      margin-top: 20px;

      @media @tablet-strict {
        flex-basis: 50%;
      }

      @media @phone {
        flex-basis: 100%;
      }
    }
  }

  .legend-section {
    display: flex;
    align-items: center;

    .square-green, .square-red {
      background-color: #66FFBD;
      width: 8px;
      height: 8px;
    }

    .square-red {
      background-color: #DC5A5A;
    }

    .inter-body {
      font-size: 14px;
      line-height: 16px;
      color: #999999;
      margin-left: 8px;
      margin-right: 40px;
    }
  }
}

.chart-container {
  overflow-x: auto;
}

.custom-select {
  position: relative;
  user-select: none;

  &.active {
    .selected {
      background: #F8F8F8;
      border-radius: 8px 8px 0 0;
      box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
    }
  }

  .selected, .options {
    width: 150px;
  }

  .selected {
    display:inline-flex;
    justify-content: center;
    align-items: center;
    padding: 7px 0;

    /deep/ span {
      display: flex;
    }
  }

  .options {
    position: absolute;
    background: #fff;
    flex-basis: 100%;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
    border-radius: 0 0 8px 8px;

    .option {
      padding: 5px 10px;

      &:last-of-type {
        border-radius: 0 0 8px 8px;
      }

      &:hover {
        background: #f8f8f8;
      }
    }
  }
}

.custom-select-modal {
  width: 100vw;
  height: 100vh;
  background: #fff;

  header {
    margin: 0 15px;

    nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;

      /deep/ span {
        display: flex;
      }
    }
  }

  .options {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .option {
      padding: 10px 15px;
    }
  }
}

.custom-mobile-only {
  @media @desktop {
    display: none;
  }
}
