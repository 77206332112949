


















































@import "../dex-shared/styles/imports.less";

.grey {
  background-color: #A4A4A4 !important;
}

.small {
  height: 8px;
  width: 8px;
}

div.level-dropdown {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 175px;
  @media @mobile {
    justify-content: center;
    height: 50px;
  } 
}

.board-content {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  h5 {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .board-statistics {
    text-align: left !important;
    display: inline-flex;
    margin-bottom: 0px;
    margin-top: 40px;

    .dex-content {
      width: 85%;
      display: inline-flex;
      align-items: center;
      margin-bottom: 0px;
      @media @large {
        margin-top: 40px;
      }

      .board-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        h3 {
          margin: 0px;
        }

        .board-subtext {
          font-family: "Aprova Sans";
          font-size: 18px;
          color: #666666;
        }
      }
    }

  }
}

.donut-chart {
  margin-right: 25px;
}

.donut-total {
  font-size: 30px;
}

.donut-details {
  font-size: 12px;
}

