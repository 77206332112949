.milestones-breakpoint {
  color: #999999;
  font-family: "Inter UI";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.6px;
}
.milestones-active {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: -0.4px;
  font-weight: bold;
}
