






































































@import "../dex-shared/styles/imports.less";

.feedback { 
margin-top: 2em;

  .cards {
    margin-top: 0.001em;
    text-align: left;
   
   
    .card-single {
      margin-top: 1em;
      display: flex;
      position: relative;
      
      &::before {
        content: "";
        padding-bottom: 56.25%;
        top:0;
      }

      .box{
        height: 100%;
        width: 100%;
      
      }
    }
  }

  h5 { /*Aproveitamento na lista*/ 
  
    @media desktop {
      margin-top: 2em;
    
    }
    @media mobile {
      font-size: 24px;
      margin-top: 0px;
      margin-bottom: 10px;
    }
  }

  h6 {
    font-size: 22px;
    margin-top: 1.2em;
  }

}

.subsections {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  margin-right: 10px;

  .subsection {
    text-align: center;
    width: 50%;
  }

  .subsection:not(.erros):not(.acertos) {
    font-size: 16px;
    font-weight: normal;
    color: #999999;
    border-bottom: 4px solid #ececec;
    display: table-cell;
    vertical-align: middle;

  }
  .erros {
    font-weight: bold;
    color: @color-enem-wrong;
    border-bottom: 4px solid @color-enem-wrong;
  }
  .acertos {
    font-weight: bold;
    color: @color-enem-correct;
    border-bottom: 4px solid @color-enem-correct;
  
  }
}

.performance-subsection-text {
  color: #616161;
}

