

























































































@import "../../dex-shared/styles/imports.less";

.send-solution {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  .input-container {
    display: flex;
    margin-bottom: 15px;
    border: 1px solid #ECECEC;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 3px;
    overflow: hidden;
    resize: none;

    img {
      width: 30px;
      height: 30px;
      margin-right: 15px;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .button-secondary {
      margin-right: 10px;
    }
  }
}

