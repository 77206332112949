





















































.feedback-container {
    margin-top: 30px;
}

.sidebar-feedback {
    border: 1px solid #e0e0e0;
    padding: 26px;

    .f-text {
        font-family: Aprova Sans;
        line-height: 16px;
        font-size: 14px;
        letter-spacing: -0.6px;
        color: #666666;
    }

    .f-c2a {
        font-family: Aprova Sans;
        line-height: normal;
        font-size: 16px;
        letter-spacing: -0.447273px;
        color: #08DB88;
    }
}

.dialog-box {
    background-color: white;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    max-width: 320px;

    .db-questions {

        .db-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px;
            color: white;
            background-color: #F2994A;
            border-radius: 8px 8px 0px 0px;
            line-height: 16px;
            font-size: 16px;
            letter-spacing: -0.2px;
        }

        .db-input {
            width: calc(100% - 30px);
            padding: 15px;
            height: 8em;
            font-family: Aprova Sans;
            line-height: 18px;
            font-size: 16px;
            color: #212121;
            border: 0px;
            resize: none;
            //outline: none;
            border-bottom: 1px solid #ECECEC;
        }

        ::placeholder {
            color: #A4A4A4;
        }

        .db-question {
            margin: 15px;
            .db-text {
                margin-bottom: 5px;
            }
            .db-buttons {
                display: flex;
                .db-button {
                    margin-right: 20px;
                }
            }
        }
    }

    .db-thanks {

        .db-header {
            display: flex;
            justify-content: flex-end;
            padding-top: 15px;
            padding-right: 15px;
        }

        .db-title {
            font-family: Aprova Sans;
            line-height: 40px;
            font-size: 32px;
            text-align: center;
            letter-spacing: -1.2px;
            color: #333333;
        }

        .db-content {
            display: flex;
            justify-content: center;
            margin: 25px;
        }

        .db-subtitle {
            font-family: Aprova Sans;
            line-height: 32px;
            font-size: 20px;
            text-align: center;
            letter-spacing: -0.6px;
            color: #666666;
        }
    }

    .db-footer {
        display: flex;
        justify-content: flex-end;
        padding: 20px;
    }

}
