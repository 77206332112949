















a {
  text-decoration: none;
}

.back-to-descomplica {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;

  &:hover {
    border-radius: 30px;
    background: #E0E0E0;
  }

  img, p {
    display: inline-block;
  }

  img {
    width: 20px;
  }

  p {
    color: #212121;
    margin-left: 5px;
  }
}

