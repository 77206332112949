






.kWidgetIframeContainer {
  width: 80%;
  height: 300px !important; 
}
