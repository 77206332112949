.item-name {
  font-size: 18px;
  letter-spacing: -0.28px;
  line-height: 27px;
  cursor: pointer;
}
.item-name:not(.item-last):not(.board) {
  font-weight: 400;
  color: #999999;
}
.list-section {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.streak-results-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 46px;
  background-color: #FFF;
}
.desktop-streak-results-info-container {
  width: 75%;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  height: 300px;
}
.desktop-close-streak-button {
  cursor: pointer;
  display: flex;
  width: 85%;
  height: 30px;
  padding-right: 32px;
  flex-direction: column;
  align-items: flex-end;
  align-self: center;
  justify-content: flex-end;
}
.desktop-streak-results-activity-container {
  align-self: center;
  width: 85%;
  margin-bottom: 24px;
}
.streak-results-activity {
  font-family: Inter UI;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.6px;
  color: #4D4D4D;
  margin-top: 8px;
}
.mobile-streak-results-info-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mobile-close-streak-button {
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
