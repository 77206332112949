

































@import '../dex-shared/styles/imports.less';

    .container {

        display: flex;
        width: 100%;
        justify-content: space-around;
        margin-top: 32px;
        padding-top: 64px;

        @media @phone {
            flex-direction: column;
        }

        @media @tablet {
            flex-direction: column;
        }

        @media @desktop {
            flex-direction: row;
        }

    }

    .no-login-message {

        @media @phone {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        @media @tablet {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        @media @desktop {
            width: 40%;
        }
    }

    .no-login-svg {
        width: 270px;
        height: 331px;
    }

    .streak-fire-container {

        display: flex;
        flex-direction: column;
        width: 100%;

        @media @phone {
            align-items: center;
        }

        @media @tablet {
            align-items: center;
        }

        @media @desktop {
            align-items: flex-start;
        }

    }

    .mobile-tablet-only {
        @media @desktop {
            display: none !important;
        }

        @media @tablet {
            display: flex;
        }

        @media @phone {
            display: flex;
        }
    }

    .desktop-only {
        @media @desktop {
            display: flex !important;
        }

        @media @tablet {
            display: none;
        }

        @media @phone {
            display: none;
        }
    }

    .streak-fire {
         @media @phone {
            width: 30px;
            height: 30px;
            align-self: center;
        }

        @media @tablet {
            width: 30px;
            height: 30px;
            align-self: center;
        }

        @media @desktop {
            width: 40px;
            height: 40px;
            align-self: flex-start;
        }
    }

    .no-login-cta-button {

         @media @phone {
            align-self: center;
        }

        @media @tablet {
            align-self: center;
        }

        @media @desktop {
            align-self: flex-start;
        }

        margin-top: 32px;
    }

    .no-login-message-header {
        margin-top: 0px;

        @media @tablet {
            font-size: 32px;
            text-align: center;
            margin-top: 8px;
        }

        @media @phone {
            font-size: 32px;
            text-align: center;
            margin-top: 8px;
        }

        @media @desktop {
            text-align: start;
        }
    }

    .no-login-message-body {
        font-family: "Inter UI";
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: -0.6px;
        color: #6D6D6D;

        @media @phone {
            margin-top: 16px;
            text-align: center;
        }

        @media @tablet {
            margin-top: 16px;
            text-align: center;
        }

        @media @desktop {
            text-align: start;
        }
    }
