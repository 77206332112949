@import "./colors.less";
@import "./mixins.less";

.notification-circle {
    height:12px;
    width: 12px;
    background-color: red;
    border: 1px solid wine;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    right:10px;
    top:-1px
}

.square {
    height: 14px;
    width: 14px;
    margin: 0;
    border-radius: 2px;
}

.square-green {
    &:extend(.square);
    background-color: @color-enem-correct;
    display: inline-flex;
}

.square-red {
    &:extend(.square);
    background-color: @color-enem-wrong;
    display: inline-flex;
}

.square-gray {
    &:extend(.square);
    background-color: #ececec;
    display: inline-flex;
}

//Ring chart
@circle-width: 0.05em;

.rect-auto {
    clip: rect(auto, auto, auto, auto);
}

.pie {
    position: absolute;
    border: @circle-width solid @color-enem-primary;
    width: 1 - (2 * @circle-width);
    height: 1 - (2 * @circle-width);
    clip: rect(0em, 0.5em, 1em, 0em);
    border-radius: 50%;
    .rotate(0deg);
}

.pie-fill {
    .rotate(180deg);
}

.ring {

    *, *:before, *:after {
        .box-sizing(content-box);
    }

    position: relative;
    font-size: 100px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    float: left;
    margin: 0 0.1em 0.1em 0;
    background-color: @color-enem-wrong;

	.ring-text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		color: @color-enem-face-subtext;
		.ring-title {
			line-height: normal;
            font-size: 30px;
			text-align: center;
			letter-spacing: 1.37px;
		}
		.ring-subtitle {
			font-family: "Aprova Sans", sans-serif;
			line-height: normal;
			font-size: 12px;
			letter-spacing: -0.702857px;
		}
	}

    // center circle to its parent
    &.center{
        float: none;
        margin: 0 auto;
    }

    // bigger size
    &.big{
        font-size: 240px;
    }

    // smaller size
    &.small{
        font-size: 80px;
    }

    // centered value inside circle
    > span {
        position: absolute;
        width: 100%;
        z-index: 1;
        left: 0;
        top: 0;
        width: 5em;
        line-height: 5em;
        font-size: 0.2em;
        color: @color-enem-wrong;
        display: block;
        text-align: center;
        white-space: nowrap;
        .transition-property(all);
        .transition-duration(0.2s);
        .transition-timing-function(ease-out);
    }

    // background inside the circle
    &:after{
        position: absolute;
        top: @circle-width;
        left: @circle-width;
        display: block;
        content: " ";
        border-radius: 50%;
        background-color: white; //@color-enem-bg;
        width: 1 - (2 * @circle-width);
        height: 1 - (2 * @circle-width);
        .transition-property(all);
        .transition-duration(0.2s);
        .transition-timing-function(ease-in);
    }

    // the slice (mask)
    .slice {
        position: absolute;
        width: 1em;
        height: 1em;
        clip: rect(0em, 1em, 1em, 0.5em);
    }

    // circle to show the status
    .bar {
        &:extend(.pie);
    }

    // loop to create all needed elements automatically
    .generate-elements(@n, @i: 1) when (@i =< @n) {
        &.p@{i} {
            & .slice {
                &:extend(.rect-auto);
            }
            & .bar:after{
                &:extend(.pie-fill);
            }
            & .fill{
                &:extend(.pie);
                &:extend(.pie-fill);
            }
        }
        .generate-elements(@n, (@i + 1));
    }
    // loop to rotate all 100 circles
    .generate-rotation(@n, @i: 1) when (@i =< @n) {
        &.p@{i} .bar {
            @degs: (360/100*@i);
            .rotate(~"@{degs}deg");
        }
        .generate-rotation(@n, (@i + 1));
    }

    .generate-elements(100, 51);
    .generate-rotation(100, 1);
}