



























































































































































































































































































































































@import '../../dex-shared/styles/imports.less';

.custom-mobile-only {
  @media @desktop {
    display: none !important;
  }
}

.custom-desktop-only {
  @media @mobile {
    display: none !important;
  }
}

.solution-card {
  margin-bottom: 40px;
  box-shadow: none;
  padding: 20px;
  background-color: transparent;

  &:hover {
    box-shadow:none;
  }
}

.solution-body {
  overflow: auto;
}

.solution-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.best-voted {
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  margin-right: 40px;

  p {
    font-size: 12px;
    color: #D87830;
  }

  /deep/ span {
    display: flex;
    margin-bottom: 2px;
    margin-right: 3px;
  }

  @media @mobile {
    margin-bottom: 15px;
    justify-content: flex-end;
    margin-right: 0;
  }
}

.user-profile {
  display: flex;
  flex-direction: row;

  .user-image {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 10px;

    img {
      width: 36px;
      height: 36px;
    }
  }

  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      color: #999;
      font-weight: normal;

      &:last-child {
        font-family: 'Inter UI', sans-serif;
      }
    }

    .user-name {
      color: #212121;
      font-weight: bold;
    }
  }

  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &.not-best-voted {
      margin-left: auto;
    }

    @media @mobile {
      margin-left: auto;
    }

    /deep/ span.clickable {
      display: flex;
      align-items: center;
      padding: 5px;
      border-radius: 50%;

      &, & svg {
        transition: all 0.2s ease-out;
      }

      &:hover, &:active, &.voted-by-user {
        background: #F8F8F8;

        svg {
          fill: #00C974 !important;
        }
      }
    }

    .menu-container {
      position: absolute;
      right: -8px;
      top: 40px;

      .menu-option {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 5px 10px;
        user-select: none;
        transition: background 0.2s ease-in;

        &:hover, &:active {
          background: #f8f8f8;
        }

        p {
          font-family: "Inter UI", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 28px;
          letter-spacing: -0.6px;
          color: #616161;
          margin-left: 3px;
        }
      }
    }
  }
}

.solution-content {
  /deep/ .content-text {
    p {
      font-family: "Inter UI", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 30px;
      letter-spacing: -0.4px;
      color: #616161;
    }
  }
}

.votes-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  .vote-question {
    margin-right: 25px;
    font-family: 'Inter UI', sans-serif;
  }

  .vote-count {
    display: flex;
    user-select: none;
    align-items: center;

    p {
      margin-right: 5px;
    }

    &.up {
      margin-right: 20px;
    }

    /deep/ span {
      display: flex;
      align-items: center;
      padding: 5px;
      border-radius: 50%;

      &, & svg {
        transition: all 0.2s ease-out;
      }
    }

    &:hover, &:active, &.voted-by-user {
      p {
        color: #00C974;
      }

      /deep/ span {
        background: #F8F8F8;

        svg {
          fill: #00C974 !important;
        }
      }
    }
  }
}

.validation {
  display: flex;
  align-items: center;
  margin-right: auto;

  @media @mobile {
    margin-top: 15px;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}

.answers-pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;

  span {
    color: #616161;
    margin: 0 15px;
    font-size: 17px;
    font-family: "Inter UI", sans-serif;
    font-weight: 700;
  }

  /deep/ svg {
    transition: all 200ms linear;

    &:hover {
      fill: #00C974 !important;
      cursor: pointer;
    }
  }
}

.loading {
  height:400px;
}
