
























@import "../../dex-shared/styles/imports.less";

.progress-bar {
  display: flex;
  margin-top: 12px;
  margin-bottom: 16px;
  border-radius: 10px;
  background: #ececec;

  .bar-section {
    height: 12px;

    &.all-correct,
    &.all-wrong {
      border-radius: 10px !important;
    }
  }
}

.legend-section {
  display: flex;
  align-items: center;

  .square-green, .square-red {
    background-color: #66FFBD;
    width: 8px;
    height: 8px;
  }

  .square-red {
    background-color: #DC5A5A;
  }

  .inter-body {
    font-size: 14px;
    line-height: 16px;
    color: #999999;
    margin-left: 8px;
    margin-right: 40px;

    @media @phone {
      margin-right: 12px;
    }

    &:last-of-type {
      margin-left: auto;
      margin-right: 0;
    }
  }
}

