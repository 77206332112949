















@import "../../dex-shared/styles/imports.less";

.board-donut-chart {
  display: inline-flex;
}
