


























@import "../styles/imports.less";

.container {
  position: relative;
}

.progress {
  position: absolute;
  top: 0;
  left: 0;
  transition-property: width;
}
