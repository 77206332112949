











































































@import "../../dex-shared/styles/imports.less";

#sidebar {
  order: -1;
  z-index: 1000;
  display: flex;
  overflow-x: visible;

  @media @mobile {
    z-index: 3000;
  }

  .sidebar-content {
    background-color: #fff;

    .sidebar-header,
    .sidebar-muted,
    .sidebar-disciplines {
      padding: 0 30px;

      @media @mobile {
        padding: 20px;
      }
    }

    .sidebar-disciplines {
      margin-top: 20px;
      display: flex;
      flex-direction: column;

      .sidebar-discipline {
        font-size: 16px;
        line-height: 2em;
        color: @darkGray;
        &:hover {
          color: @green;
          transition: all 0.3s ease;
        }
      }

      .current-board {
        font-weight: bold;
        color: #111111;
      }
    }
  }
}
