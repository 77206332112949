










@import '../../dex-shared/styles/imports.less';




.correct-card:hover {
  transition: 0.3s ease-in;
}

/*.correct-answer {
  width: 60px;
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  color: white;
  background-color: @color-enem-correct;
  text-align: center;
  font-size: 14px;
}*/ 

