






























































































































































































































































































@import '../../dex-shared/styles/imports.less';

.question-map-carousel {
  height: 66px;
  display: flex;
  align-items: center;
  max-width: 100vw;
  .qm-carousel {
    width: calc(100% - 2*48px);
    max-width: calc(1024px - 2*48px);
  }
}

.question-map {
  height: 66px;
}

.qn-connection {
  margin-top: -34px;
  margin-left: 20px;
  margin-right: 20px;
  width: calc(100% - 40px);
  height: 4px;
  background-color: #ececec;
}

.questions-navigation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  height: 66px;

  @media @mobile{
    justify-content: space-around;
  }
}

.questions-navigation-item {
  cursor: pointer;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  border-radius: 4px;

  @media @desktop {
    margin: 10px;
  }
  @media mobile {
   margin: 0px;
  }

  &:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    transition: 0.3s ease;
  }
}

.current-question {
  width: 28px !important;
  height: 28px !important;
  cursor: default !important;
  margin-right: 5px;

  &:hover {
    box-shadow: none !important;
  }
}

.current-article {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @color-enem-gray;
  border-radius: 15px;
}

.questions-navigation-item-blank {
  &:extend(.questions-navigation-item);
  background-color: rgba(248, 248, 248, 1);
  color: rgba(200, 200, 200, 1);
  border-style: solid;
  border-width: 1px;
  border-color: rgba(200, 200, 200, 1);

  &:hover {
    .questions-navigation-item;
  }
}

.questions-navigation-item-blank.current-question {
  background-color: rgba(200, 200, 200, 1);
  color: rgba(17, 17, 17, 1);
}

.questions-navigation-item-correct {
  &:extend(.questions-navigation-item);
  background-color: rgba(0, 221, 136, 1);
  color: white;

  &:hover {
    .questions-navigation-item;
  }
}

.questions-navigation-item-wrong {
  &:extend(.questions-navigation-item);
  background-color: rgba(220, 90, 90, 1);
  color: white;

  &:hover {
    .questions-navigation-item;
  }
}

.questions-navigation-item-text {
  font-family: "AprovaSans-Regular", Helvetica, Arial, serif;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.3px;
  line-height: 22px;
}

.video-bullet {
  .play-icon {
    height: 24px;
    display: block;
  }
}
