




















@import "./dex-shared/styles/imports.less";

@font-face {
  font-family: "Aprova Sans";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Aprova-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Aprova Sans";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/Aprova-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Aprova Sans";
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/Aprova-Black.otf") format("opentype");
}

@font-face {
  font-family: "Inter UI";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/Inter-UI/Inter-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Inter UI";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/Inter-UI/Inter-Bold.otf") format("opentype");
}

body {
  font-family: "Aprova Sans";
  font-weight: 400;
  margin: 0px;
  background-color: white;

}

.yield {
  background-color: white;
  width: 100%;
  display: flex;
  flex-grow: 1;
  padding: 0;
  text-align: left;
}

.unfocused-yield {
  @media @desktop {
    max-width: calc(100% - 250px);
  }
}

.no-sidebar {
  margin: 0 auto;
}

.clickable {
  cursor: pointer;
}

.desktop-only {
  @media screen and (max-width: 600px) {
    display: none !important;
  }
}

.mobile-only {
  @media screen and (min-width: 600px) {
    display: none !important;
  }
}

.slide-enter, .slide-leave-to {
  margin-left: -66%;
}

.slide-enter-active, .slide-leave-active {
  transition: margin-left .5s ease-out;
}

.slide-enter-to, .slide-leave {
  margin-left: 0px;
}

.overlay {
  z-index: 2;
  height: 100%;
  width: 100%;
  background-color: #111111;
  opacity: 0.5;
  position: fixed;
}

.fade-enter-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.no-decoration {
  text-decoration: none;
}
