a {
  text-decoration: none;
}
.back-to-descomplica {
  display: inline-flex;
  align-items: center;
  padding: 5px 10px;
}
.back-to-descomplica:hover {
  border-radius: 30px;
  background: #E0E0E0;
}
.back-to-descomplica img,
.back-to-descomplica p {
  display: inline-block;
}
.back-to-descomplica img {
  width: 20px;
}
.back-to-descomplica p {
  color: #212121;
  margin-left: 5px;
}
