



















@import '../dex-shared/styles/imports.less';

.breadcrumb {
  display: flex;
  flex-direction: row;
  align-items: end;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 20px;
  min-width: 0;
  flex-shrink: 1;

  a:first-child {
    flex-grow: 1;
    white-space: nowrap;
  }

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 330px;
  }
}

.board {
  color: #212121;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: -1.2px;
  margin-bottom: 20px;
}

.item-last {
  font-weight: bold;
  color: #212121;
}
