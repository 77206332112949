











@import "../../dex-shared/styles/imports.less";
#sidebar {
  padding: 26px 0;
  min-width: 160px;
  height: auto;
  border-right: 1px solid #e0e0e0;
  flex-direction: column;

  @media @mobile {
    display: none;
  }

  .sidebar-disciplines {
    display: flex;
    flex-direction: column;
  }

  .sidebar-muted {
    font-size: 14px;
    color: #999999;
    margin-bottom: 20px;
    padding: 3px;
  }

}
