.content-image {
  overflow: auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
img {
  align-self: center;
  margin-bottom: 15px;
  max-width: 90%;
  height: auto;
}
.image-description,
.image-origin {
  font-family: "Inter UI", "Aprova Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
  font-size: 18px;
  text-align: center;
  letter-spacing: -0.4px;
  color: #666666;
}
.image-origin {
  font-size: 16px;
  color: #999999;
  text-align: right;
}
