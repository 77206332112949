.exercises-homelink {
  margin-right: 24px;
}
.mobile-routes {
  display: flex;
}
.navbar-divider {
  border-bottom: 1px solid #ececec;
  padding-top: 4px;
  padding-bottom: 4px;
}
.mobile-routes-navigation-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.subtitle-1 {
  font-weight: bold;
  margin-right: 20px;
  text-decoration: none;
}
.subtitle-1:hover {
  text-decoration: underline;
}
@media only screen and (max-width: 1023px) {
  .subtitle-1 {
    margin-top: 2px;
  }
}
.navbar {
  position: relative;
}
@media only screen and (min-width: 1024px) {
  .navbar {
    z-index: 5;
  }
}
.logo {
  height: 35px;
}
@media screen and (min-width: 1024px) {
  .no-desktop {
    display: none;
  }
}
#nav-dex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  background-color: white;
}
@media only screen and (min-width: 600px) {
  #nav-dex {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media only screen and (max-width: 599px) {
  #nav-dex {
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
  }
}
#nav-dex #nav-center {
  display: none;
}
@media only screen and (min-width: 1024px) {
  #nav-dex #nav-center {
    display: flex;
    align-items: center;
  }
}
#nav-dex #nav-right {
  text-align: right;
  display: flex;
  align-items: center;
}
#button {
  margin: 0 1.5em;
}
.mobile-exercises-navbar {
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
}
#profile-picture {
  width: 25px;
  height: 25px;
  margin-bottom: -4px;
}
@media only screen and (max-width: 1023px) {
  .button-primary {
    font-size: 14px;
  }
}
@media only screen and (min-width: 1024px) {
  .mobile-search {
    display: none;
  }
}
.mobile-search main {
  background: #fff;
  height: 100vh;
  width: 100vw;
}
.mobile-icon {
  display: none;
}
@media only screen and (max-width: 1023px) {
  .mobile-icon {
    display: block;
  }
}
.search-nav {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
}
@media only screen and (max-width: 1023px) {
  .search-nav {
    display: inline-flex;
  }
}
